<template>
  <div>
    1
  </div>
</template>
<script>
export default {
  mounted() {
    const id=this.$route.query.id
    this.$router.push({path:'/index/product/detail.html',query:{id:id}})
  }
}
</script>